import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Col } from 'reactstrap';

import _isEmpty from 'lodash.isempty';

import TopLoader from '../components/TopLoader';
import CampaignForm from '../components/CampaignForm';

import { TOP_LOAD_RATE, TOP_LOAD_START } from '../../constants';

import { useStoreState, useStoreActions } from '../store';

const EditCampaign = () => {
  const ref = useRef(null);
  const params = useParams();
  const id = params?.id;

  const navigate = useNavigate();

  const campaign = useStoreState((state) => state.campaign.campaign);
  const subscription = useStoreState((state) => state.subscription.subscription);
  const isImpersonating = useStoreState((state) => state.impersonation.isImpersonating);

  const editState = useStoreState((state) => state.campaign.editState);
  const campaignState = useStoreState((state) => state.campaign.campaignState);

  const reset = useStoreActions((actions) => actions.campaign.reset);
  const getCampaign = useStoreActions((actions) => actions.campaign.getCampaign);
  const editCampaign = useStoreActions((actions) => actions.campaign.editCampaign);

  const returnToCampaigns = () => navigate('/campaigns');

  if (isImpersonating) returnToCampaigns();

  useEffect(() => {
    if (id) getCampaign(id!);
  }, [id]);

  useEffect(() => {
    if (editState.pending) {
      (ref.current as any).continuousStart(
        TOP_LOAD_START,
        TOP_LOAD_RATE,
      );
    }

    if (editState.success) {
      (ref.current as any).complete();

      reset();
      returnToCampaigns();
    } else if (editState.failure) (ref.current as any).complete();
  }, [editState]);

  useEffect(() => {
    if (campaignState.pending) {
      (ref.current as any).continuousStart(
        TOP_LOAD_START,
        TOP_LOAD_RATE,
      );
    }

    if (campaignState.success || campaignState.failure) (ref.current as any).complete();
  }, [campaignState]);

  return (
    <Container className="d-flex justify-content-center">
      <TopLoader reference={ref} />
      <Col style={{ width: '90vw' }}>
        {campaignState.success && campaign && (
          <CampaignForm
            campaign={campaign}
            onSubmit={(data) => editCampaign({ id: id!, data })}
            onCancel={returnToCampaigns}
            availableFeatures={subscription?.availableFeatures ?? ['email', 'sms']}
          />
        )}
      </Col>
    </Container>
  );
};

export default EditCampaign;

import React from 'react';
import { Badge } from 'reactstrap';
import { ResponseTime } from '../../types';
import { useStoreState } from '../store';

const ProspectAlertBadge = () => {
  const responseTimes = useStoreState((state) => state.prospect.responseTimeList);
  const responseTimeState = useStoreState((state) => state.prospect.responseTimeState);

  const removeDuplicates = (myArr: ResponseTime[], prop: string) => {
    if (!myArr) return [];
    return myArr.filter((obj, pos, arr) => arr
      .map((mapObj) => (mapObj as any)[prop])
      .indexOf((obj as any)[prop]) === pos);
  };

  const uniqueUnrespondedProspects = removeDuplicates(
    responseTimes,
    'prospectId',
  );

  const number = !!uniqueUnrespondedProspects.length
    && uniqueUnrespondedProspects.length;

  return (
    <>
      {responseTimeState.success && (
        <Badge
          color="danger"
          style={{
            position: 'absolute',
            top: number > 9 ? '-10px' : '-7px',
            right: number > 9 ? '-4px' : '-5px',
            borderRadius: '50px',
            padding: '0.5em 0.7em',
            fontWeight: 'normal',
          }}
        >
          {number}
        </Badge>
      )}
    </>
  );
};

export default ProspectAlertBadge;

import {
  action,
  Action,
  thunk,
  Thunk,
} from 'easy-peasy';
import { DEFAULT_API_CALL_STATE, DEFAULT_QUERY_LIMIT } from '../../constants';

import api from '../../api';
import {
  APICallState,
  Prospect,
  ProspectStatus,
  ResponseTime,
} from '../../types';

enum APICall {
  prospect,
  prospectList,
  responseTimeList,
}

// get response times
export interface ProspectModel {
  // State
  prospect: Prospect | null;
  prospectList: Prospect[];
  prospectCount: number;
  responseTimeList: ResponseTime[];
  responseTimeCount: number;
  prospectState: APICallState;
  prospectListState: APICallState;
  responseTimeState: APICallState;
  // Setters
  setProspect: Action<ProspectModel, Prospect>;
  setList: Action<ProspectModel, { apiCall: APICall, list: any[], count: number }>;
  setAPICallState: Action<ProspectModel, { apiCall: APICall, value: APICallState }>;
  // Methods
  getProspect: Thunk<ProspectModel, string>;
  getProspects: Thunk<ProspectModel, { userId: string, order: string }>;
  getResponseTimes: Thunk<ProspectModel, { userId: string, }>;
}

const prospect = (): ProspectModel => ({
  prospect: null,
  prospectList: [],
  prospectCount: 0,
  responseTimeList: [],
  responseTimeCount: 0,
  prospectState: { ...DEFAULT_API_CALL_STATE },
  prospectListState: { ...DEFAULT_API_CALL_STATE },
  responseTimeState: { ...DEFAULT_API_CALL_STATE },
  setProspect: action((state, data) => {
    state.prospect = data;
  }),
  setList: action((state, data) => {
    // eslint-disable-next-line default-case
    switch (data.apiCall) {
      case APICall.prospectList:
        state.prospectList = data.list;
        state.prospectCount = data.count;
        break;
      case APICall.responseTimeList:
        state.responseTimeList = data.list;
        state.responseTimeCount = data.count;
        break;
    }
  }),
  setAPICallState: action((state, data) => {
    // eslint-disable-next-line default-case
    switch (data.apiCall) {
      case APICall.prospect:
        state.prospectState = data.value;
        break;
      case APICall.prospectList:
        state.prospectListState = data.value;
        break;
      case APICall.responseTimeList:
        state.responseTimeState = data.value;
        break;
    }
  }),
  getProspect: thunk(
    async ({ setAPICallState, setProspect }, id) => {
      setAPICallState({
        apiCall: APICall.prospect,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        const result = await api.prospects.findById(id);

        setProspect(result.data);
        setAPICallState({
          apiCall: APICall.prospect,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.prospect,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });
      }
    },
  ),
  getProspects: thunk(
    async ({ setAPICallState, setList }, query) => {
      setAPICallState({
        apiCall: APICall.prospectList,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        const result = await api.prospects.find({
          skip: 0,
          limit: DEFAULT_QUERY_LIMIT,
          ordering: query.order,
          filter: {
            userId: query.userId,
            status: ProspectStatus.Prospect,
          },
        });

        setList({
          apiCall: APICall.prospectList,
          list: result.response.data,
          count: result.response.pageCount,
        });

        setAPICallState({
          apiCall: APICall.prospectList,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.prospectList,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });
      }
    },
  ),
  getResponseTimes: thunk(
    async ({ setAPICallState, setList }, { userId }) => {
      setAPICallState({
        apiCall: APICall.responseTimeList,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        const result = await api.prospects.findResponseTimes({
          skip: 0,
          limit: DEFAULT_QUERY_LIMIT,
          ordering: '-createdAt',
          filter: {
            userId,
            endTime: null,
          },
        });

        setList({
          apiCall: APICall.responseTimeList,
          list: result.response.data,
          count: result.response.pageCount,
        });

        setAPICallState({
          apiCall: APICall.responseTimeList,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.responseTimeList,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });
      }
    },
  ),
});

export default prospect;

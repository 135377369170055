import { createStore, createTypedHooks } from 'easy-peasy';

import authentication, { AuthenticationModel } from './authentication';
import campaign, { CampaignModel } from './campaign';
import impersonation, { ImpersonationModel } from './impersonation';
import lead, { LeadModel } from './lead';
import message, { MessageModel } from './message';
import meta, { MetaModel } from './meta';
import profile, { ProfileModel } from './profile';
import prospect, { ProspectModel } from './prospect';
import registration, { RegistrationModel } from './registration';
import subscription, { SubscriptionModel } from './subscription';
import team, { TeamModel } from './team';
import tenant, { TenantModel } from './tenant';
import user, { UserModel } from './user';

export interface StoreModel {
  authentication: AuthenticationModel;
  campaign: CampaignModel;
  impersonation: ImpersonationModel;
  lead: LeadModel;
  message: MessageModel;
  meta: MetaModel;
  profile: ProfileModel;
  prospect: ProspectModel;
  registration: RegistrationModel;
  subscription: SubscriptionModel;
  team: TeamModel;
  tenant: TenantModel;
  user: UserModel;
}

const model: StoreModel = {
  authentication: authentication(),
  campaign: campaign(),
  impersonation: impersonation(),
  lead: lead(),
  message: message(),
  meta: meta(),
  profile: profile(),
  prospect: prospect(),
  registration: registration(),
  subscription: subscription(),
  team: team(),
  tenant: tenant(),
  user: user(),
};

const index = createStore(model, {
  name: 'gfi-app-store',
  devTools: process.env.REACT_APP_ENV === 'development',
});

export default index;

export const { useStoreState, useStoreActions, useStoreDispatch } = createTypedHooks<StoreModel>();

const getCurrentTenantDomain = () => {
  const location = window.location.host;
  const parts = window.location.host.split('.');

  if (
    parts.length <= 2
    || ['dev', 'staging', 'demo', 'app'].includes(parts[0].toLowerCase())
  ) {
    return 'default';
  }

  return location;
};

export default getCurrentTenantDomain;

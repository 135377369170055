import React, { Suspense, useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import { useAuth0 } from '@auth0/auth0-react';

import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';

// Components
import Header from './components/Header';
import Loading from './components/Loading';
import GuideRoute from './components/GuideRoute';
import PrivateOutlet from './components/PrivateOutlet';
import PrivateRoute from './components/PrivateRoute';

// Pages
import Campaign from './pages/Campaign';
import Campaigns from './pages/Campaigns';
import CreateCampaign from './pages/CreateCampaign';
import CreateTeam from './pages/CreateTeam';
import CreateUser from './pages/CreateUser';
import EditCampaign from './pages/EditCampaign';
import Entry from './pages/Entry';
import Leads from './pages/Leads';
import Messages from './pages/Messages';
import Payment from './pages/Payment';
import Prospects from './pages/Prospects';
import Signup from './pages/Signup';
import Subscription from './pages/Subscription';
import Users from './pages/Users';

import { isDefaultState } from '../utils/apiCallState';
import getCurrentTenantDomain from '../utils/whitelabel';
import { NON_AUTHORIZED_REDIRECT_URL } from '../constants';

import { useStoreActions, useStoreState } from './store';

import 'react-toastify/dist/ReactToastify.css';

// TODO: Not handling tabulated data
// TODO: Show loading indicator if waiting for profile or tenant
// TODO: We don't reset the screen when we leave lead messages for example
const App = () => {
  const {
    isLoading: isLoadingAuth0,
    getAccessTokenSilently,
    isAuthenticated: isAuth0Authenticated,
  } = useAuth0();

  const tenant = useStoreState((state) => state.tenant.tenant);
  const tenantState = useStoreState((state) => state.tenant.tenantState);
  const isAuthenticated = useStoreState((state) => state.authentication.isAuthenticated);

  const startAuthenticatedSession = useStoreActions((actions) => actions
    .authentication.startAuthenticatedSession);

  const getTenant = useStoreActions((actions) => actions.tenant.getTenant);
  const getTenants = useStoreActions((actions) => actions.tenant.getTenants);

  const [isLoading, setIsLoading] = useState(true);

  const faviconUrl = tenantState.pending
    && tenant
    && (tenant?.faviconUrl ?? '/favicon.ico');
  const title = tenantState.pending ? tenant?.name : 'Go Fetch It';

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently({
        audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
        scope: 'read:current_user',
      });
    } catch (e: any) {
      console.log(e.message);
    }

    return '';
  };

  // Set the auth token and trigger the profile related tasks
  useEffect(() => {
    (async () => {
      if (isAuthenticated !== isAuth0Authenticated) {
        setIsLoading(true);
        let token: string = '';

        if (isAuth0Authenticated) token = await getAccessToken();

        startAuthenticatedSession({ isAuthenticated: isAuth0Authenticated, token });
      }
      setIsLoading(false);
    })();
  }, [isAuth0Authenticated, isAuthenticated]);

  // Get tenant
  useEffect(() => {
    (async () => {
      if (isDefaultState(tenantState)) {
        const domain = getCurrentTenantDomain();

        try {
          if (isEmpty(tenant) || tenant?.domain !== domain) {
            if (domain === 'default') {
              await getTenant('dev.gofetch.it');
              getTenants();
            } else await getTenant(domain);
          }
        } catch (err) {
          // do nothing for now. Just falls back to
          // normal functionality.
          console.log(err);
        }
      }
    })();
  }, [tenantState]);

  // eslint-disable-next-line no-undef
  const wrapComponent = (comp: JSX.Element) => (
    <>
      <Header />
      {comp}
    </>
  );

  /* eslint-disable react/jsx-one-expression-per-line */
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
      />
      <Helmet defaultTitle={title}>
        {/* eslint-disable-next-line react/no-invalid-html-attribute */}
        <link rel="shortcut icon" href={faviconUrl as string} />
      </Helmet>
      {isLoading || isLoadingAuth0 ? 'Loading...' : (
        <Suspense fallback={Loading}>
          <BrowserRouter>
            <Routes>
              {/* Public/Unauthenticated Routes */}
              <Route path="/" element={<Navigate to={NON_AUTHORIZED_REDIRECT_URL} />} />
              <Route
                path={NON_AUTHORIZED_REDIRECT_URL}
                element={<GuideRoute><Entry /></GuideRoute>}
              />
              <Route path="/signup" element={<GuideRoute><Signup /></GuideRoute>} />

              {/* Private Routes */}
              <Route path="/campaigns" element={wrapComponent(<PrivateOutlet />)}>
                <Route path="" element={<Campaigns />} />
                <Route path="/campaigns/new" element={<CreateCampaign />} />
                <Route path="/campaigns/:id" element={<Campaign />} />
                <Route path="/campaigns/:id/edit" element={<EditCampaign />} />
              </Route>
              <Route path="/leads" element={wrapComponent(<PrivateOutlet />)}>
                <Route path="" element={<Leads />} />
                <Route path="/leads/:id" element={<Messages isProspect={false} />} />
              </Route>
              <Route path="/prospects" element={wrapComponent(<PrivateOutlet />)}>
                <Route path="" element={<Prospects />} />
                <Route path="/prospects/:id" element={<Messages />} />
              </Route>
              <Route path="/team" element={wrapComponent(<PrivateOutlet />)}>
                <Route path="" element={<Users />} />
                <Route path="/team/new" element={<CreateTeam />} />
                <Route path="/team/new/member" element={<CreateUser />} />
              </Route>
              <Route path="/payment" element={wrapComponent(<PrivateRoute><Payment /></PrivateRoute>)} />
              <Route path="/subscription" element={wrapComponent(<PrivateRoute><Subscription /></PrivateRoute>)} />
              <Route
                path="*"
                element={(
                  <main style={{ padding: '1rem' }}>
                    <p>There is nothing here!</p>
                    {/* Trigger build */}
                  </main>
                )}
              />
            </Routes>
          </BrowserRouter>
        </Suspense>
      )}
    </>
  );
  /* eslint-enable react/jsx-one-expression-per-line */
};

export default App;

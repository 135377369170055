import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { LOGGED_IN_REDIRECT_URL } from '../../constants';

const GuideRoute = ({ children }: { children: any }) => {
  const { isAuthenticated } = useAuth0();

  return !isAuthenticated ? children : <Navigate to={LOGGED_IN_REDIRECT_URL} />;
};

export default GuideRoute;

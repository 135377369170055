import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faUser } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { MOMENT_FORMATS } from '../../constants';
import { User, UserRole } from '../../types';
import { useStoreActions, useStoreState } from '../store';

const UserListItem = ({ user }: { user: User }) => {
  const {
    firstName,
    lastName,
    role,
  } = user;

  const profile = useStoreState((state) => state.profile.user);
  const impersonatedUser = useStoreState((state) => state.impersonation.user);

  const isImpersonatingThisUser = user._id === impersonatedUser?._id;

  const avgResponseFormatted = user.avgResponse === 'No Responses'
    ? user.avgResponse
    : (moment
      .duration(user.avgResponse, 'milliseconds') as any)
      .format(MOMENT_FORMATS.TIME_ELAPSED, {
        useSignificantDigits: true,
        precision: 2,
        trunc: true,
      });

  const impersonate = useStoreActions((actions) => actions.impersonation.setImpersonation);

  const canImpersonate = (myTeams: any[], userTeams: any[]) => {
    if (profile?._id === user._id) return false;

    if (profile?.role === UserRole.Owner) {
      return true;
    }
    if (profile?.role === UserRole.TeamLeader) {
      const myTeamLeadIds = myTeams.filter((t) => t.isLead).map((t) => t.teamId);
      return !!userTeams.find((t) => myTeamLeadIds.includes(t.teamId));
    }
    return false;
  };

  return (
    <Row
      className={`flex border border-${
        isImpersonatingThisUser ? 'danger' : 'secondary'
      } rounded my-4`}
    >
      <Col className="col-auto d-flex justify-content-center align-items-center">
        <FontAwesomeIcon icon={faUser as any} className="text-primary" />
      </Col>
      <Col className="pl-3">
        <Row className="py-3 d-flex align-items-center">
          <Col className="md-3 d-flex align-items-center">
            <p className="listItemName text-primary">
              {`${firstName} ${lastName}`}
            </p>
          </Col>
          <Col className="md-3">
            <p className="listItemName text-secondary ml-4">
              {role}
            </p>
          </Col>
          <Col className="md-3">
            <p className="listItemName text-secondary ml-4">
              {`Average Response: ${avgResponseFormatted}`}
            </p>
          </Col>
        </Row>
      </Col>
      <Col className="col-auto d-flex justify-content-center align-items-center">
        {canImpersonate(profile?.teams ?? [], user?.teams ?? []) ? (
          !isImpersonatingThisUser
          && user.role !== UserRole.Admin
          && user.role !== UserRole.Owner
          && (
            <FontAwesomeIcon
              onClick={() => impersonate(user)}
              icon={faEye as any}
              className="text-secondary"
            />
          )
        ) : (<></>)}
      </Col>
    </Row>
  );
};

export default UserListItem;

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { Input, Tooltip } from 'reactstrap';

// TODO: the tooltip isn't showing
const ExportInput = ({
  disabled,
  value,
  setValue,
}: { disabled?: boolean, value: boolean, setValue: Function }) => {
  const [showingTooltip, setIsShowingTooltip] = useState(false);

  const toggleTooltip = () => setIsShowingTooltip(!setIsShowingTooltip);

  return (
    <>
      <div>
        <p>
          Auto Export
          <FontAwesomeIcon
            id="auto-export-tooltip"
            icon={faQuestionCircle as any}
          />
        </p>
        <Tooltip
          placement="right"
          isOpen={showingTooltip}
          target="auto-export-tooltip"
          toggle={toggleTooltip}
        >
          Will mark new prospects with the export status and allow Zapier
          to pull them via the &quot;Export Prospects&quot; trigger.
        </Tooltip>
      </div>
      <Input
        disabled={disabled}
        type="checkbox"
        onChange={(e) => setValue(e.target.checked)}
        checked={value}
      />
    </>
  );
};

export default ExportInput;

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Paywall from './Paywall';

import { NON_AUTHORIZED_REDIRECT_URL } from '../../constants';

const PrivateRoute = ({ children }: { children: any }) => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated
    ? (
      <>
        <Paywall />
        {children}
      </>
    ) : <Navigate to={NON_AUTHORIZED_REDIRECT_URL} />;
};

export default PrivateRoute;

import axios from 'axios';
import {
  action,
  Action,
  thunk,
  Thunk,
} from 'easy-peasy';
import { StoreModel } from '.';
import { DEFAULT_API_CALL_STATE, DEFAULT_QUERY_LIMIT } from '../../constants';

import api from '../../api';
import {
  APICallState,
  Campaign,
  ToastType,
  Touchpoint,
  TouchpointType,
} from '../../types';

export interface CampaignListQuery {
  userId?: string;
  campaignIds?: string[];
}

enum APICall {
  campaign,
  campaignList,
  create,
  edit,
  remove,
}

export interface CampaignModel {
  // State
  campaign: Campaign | null;
  campaignState: APICallState;
  campaignList: Campaign[];
  campaignListState: APICallState;
  createState: APICallState;
  editState: APICallState;
  removeState: APICallState;
  // Setters
  setCampaign: Action<CampaignModel, Campaign>;
  setCampaignList: Action<CampaignModel, Campaign[]>;
  setAPICallState: Action<CampaignModel, { apiCall: APICall, value: APICallState }>;
  reset: Action<CampaignModel>;
  // Methods
  getCampaign: Thunk<CampaignModel, string>;
  getCampaigns: Thunk<CampaignModel, CampaignListQuery>;
  processTouchpoints: Thunk<CampaignModel, {
    userId: string,
    touchpoints: Touchpoint[],
  }, null, StoreModel>;
  createCampaign: Thunk<CampaignModel, Campaign, null, StoreModel>;
  editCampaign: Thunk<CampaignModel, { id: string, data: Campaign }, null, StoreModel>;
  removeCampaign: Thunk<CampaignModel, string, null, StoreModel>;
}

const campaign = (): CampaignModel => ({
  campaign: null,
  campaignState: { ...DEFAULT_API_CALL_STATE },
  campaignList: [],
  campaignListState: { ...DEFAULT_API_CALL_STATE },
  createState: { ...DEFAULT_API_CALL_STATE },
  editState: { ...DEFAULT_API_CALL_STATE },
  removeState: { ...DEFAULT_API_CALL_STATE },
  setCampaign: action((state, data) => {
    state.campaign = data;
  }),
  setCampaignList: action((state, campaignList) => {
    state.campaignList = campaignList;
  }),
  setAPICallState: action((state, data) => {
    // eslint-disable-next-line default-case
    switch (data.apiCall) {
      case APICall.campaign:
        state.campaignState = data.value;
        break;
      case APICall.campaignList:
        state.campaignListState = data.value;
        break;
      case APICall.create:
        state.createState = data.value;
        break;
      case APICall.edit:
        state.editState = data.value;
        break;
      case APICall.remove:
        state.removeState = data.value;
        break;
    }
  }),
  reset: action((state) => {
    state.editState = { ...DEFAULT_API_CALL_STATE };
    state.createState = { ...DEFAULT_API_CALL_STATE };
  }),
  getCampaign: thunk(
    async ({ setCampaign, setAPICallState }, id) => {
      setAPICallState({
        apiCall: APICall.campaign,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        const result = await api.campaigns.findById(id);

        setCampaign(result.data);
        setAPICallState({
          apiCall: APICall.campaign,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.campaign,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });
      }
    },
  ),
  getCampaigns: thunk(
    async ({ setCampaignList, setAPICallState }, query) => {
      setAPICallState({
        apiCall: APICall.campaignList,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        const result = await api.campaigns.find({
          skip: 0,
          limit: DEFAULT_QUERY_LIMIT,
          ordering: '-createdAt',
          filter: { ...query },
        });

        setCampaignList(result.response.data);
        setAPICallState({
          apiCall: APICall.campaignList,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.campaignList,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });
      }
    },
  ),
  processTouchpoints: thunk(
    async (_, { userId, touchpoints }, helpers) => {
      const { meta: { notify } } = helpers.getStoreActions();

      const updatedTouchpoints: Touchpoint[] = [];

      /* eslint-disable no-await-in-loop */
      // eslint-disable-next-line no-restricted-syntax
      for (const touchpoint of touchpoints) {
        try {
          if (touchpoint.type === TouchpointType.FancyEmail) {
            touchpoint.type = TouchpointType.Email;
            updatedTouchpoints.push(touchpoint);
          } else if (touchpoint.newFile) {
            const fileType = touchpoint.newFile.name.split('.').pop();
            const {
              data: {
                url,
                putUrl,
              },
            } = await api.rvm.getSignedUrl({
              userId,
              fileExtension: fileType,
            });

            await axios.put(putUrl, touchpoint.newFile, { headers: { 'Content-Type': '' } });

            updatedTouchpoints.push({
              ...touchpoint,
              rvmUrl: url,
              newFile: undefined,
              message: undefined,
              subject: undefined,
            });
          } else updatedTouchpoints.push(touchpoint);
        } catch (ex) {
          updatedTouchpoints.push({
            ...touchpoint,
            rvmUrl: undefined,
            newFile: undefined,
            message: undefined,
            subject: undefined,
          });

          notify({
            message: `Failed to process the rvm file for ${touchpoint.name}`,
            type: ToastType.error,
            stay: true,
          });
        }
      }
      /* eslint-enable no-await-in-loop */

      return updatedTouchpoints;
    },
  ),
  createCampaign: thunk(
    async ({ setAPICallState, processTouchpoints }, data, helpers) => {
      const { profile: { getProfile }, meta: { notify } } = helpers.getStoreActions();

      setAPICallState({
        apiCall: APICall.create,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        const processedTouchpoints = await processTouchpoints({
          userId: data.userId!,
          touchpoints: data.touchpoints,
        });

        await api.campaigns.create({
          ...data,
          touchpoints: processedTouchpoints,
        });

        setAPICallState({
          apiCall: APICall.create,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });

        notify({
          message: 'Created a new campaign',
          type: ToastType.success,
        });

        getProfile({ lean: true });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.create,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });

        notify({
          message: 'Failed to create a new campaign',
          type: ToastType.error,
        });
      }
    },
  ),
  editCampaign: thunk(
    async ({ setAPICallState, processTouchpoints }, { id, data }, helpers) => {
      const { profile: { getProfile }, meta: { notify } } = helpers.getStoreActions();

      setAPICallState({
        apiCall: APICall.edit,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        const processedTouchpoints = await processTouchpoints({
          userId: data.userId!,
          touchpoints: data.touchpoints,
        });

        await api.campaigns.update(id, {
          ...data,
          touchpoints: processedTouchpoints,
        });

        setAPICallState({
          apiCall: APICall.edit,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });

        notify({
          message: 'Updated the campaign',
          type: ToastType.success,
        });

        getProfile({ lean: true });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.edit,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });

        notify({
          message: 'Failed to update the campaign',
          type: ToastType.error,
        });
      }
    },
  ),
  removeCampaign: thunk(
    async ({ setAPICallState }, id, helpers) => {
      const { profile: { getProfile }, meta: { notify } } = helpers.getStoreActions();

      setAPICallState({
        apiCall: APICall.edit,
        value: { ...DEFAULT_API_CALL_STATE, pending: true },
      });

      try {
        await api.campaigns.delete(id);

        setAPICallState({
          apiCall: APICall.edit,
          value: { ...DEFAULT_API_CALL_STATE, success: true },
        });

        notify({
          message: 'Deleted the campaign',
          type: ToastType.success,
        });

        getProfile({ lean: true });
      } catch (ex) {
        setAPICallState({
          apiCall: APICall.edit,
          value: { ...DEFAULT_API_CALL_STATE, failure: true },
        });

        notify({
          message: 'Failed to delete the campaign',
          type: ToastType.error,
        });
      }
    },
  ),
});

export default campaign;

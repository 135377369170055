import React from 'react';
import ReactDOM from 'react-dom';
import { StoreProvider } from 'easy-peasy';
import { Auth0Provider } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';

import App from './app/App';
import store from './app/store';
// import reportWebVitals from './reportWebVitals';

import './index.scss';

import { NON_AUTHORIZED_REDIRECT_URL, LOGGED_IN_REDIRECT_URL } from './constants';

const history = createBrowserHistory();

const onRedirectCallback = () => history.push(`${window.location.origin}${LOGGED_IN_REDIRECT_URL}`);

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
    audience={process.env.REACT_APP_AUTH0_API_AUDIENCE ?? ''}
    redirectUri={`${window.location.origin}${NON_AUTHORIZED_REDIRECT_URL}`}
    scope="read:current_user"
    onRedirectCallback={onRedirectCallback}
  >
    <StoreProvider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </StoreProvider>
  </Auth0Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

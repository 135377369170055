import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';

import _isEmpty from 'lodash.isempty';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';

import genKey from '../../utils/genKey';

import TopLoader from '../components/TopLoader';
import ListHeader from '../components/ListHeader';
import ProspectListItem from '../components/ProspectListItem';

import { PROSPECT_BACKGROUNDS, TOP_LOAD_RATE, TOP_LOAD_START } from '../../constants';

import { useStoreState, useStoreActions } from '../store';

import '../../styles/prospects.scss';

// TODO: Every five minutes for this page refresh
const PROSPECTS_POLLING_TIME = 1000 * 60 * 5;

const Prospects = () => {
  const ref = useRef(null);

  const profile = useStoreState((state) => state.profile.user);

  const prospects = useStoreState((state) => state.prospect.prospectList);
  const prospectListState = useStoreState((state) => state.prospect.prospectListState);

  const responseTimes = useStoreState((state) => state.prospect.responseTimeList);
  const responseTimeListState = useStoreState((state) => state.prospect.responseTimeState);

  const {
    user: impersonatedUser,
    isImpersonating,
  } = useStoreState((state) => state.impersonation);
  const profileId = useStoreState((state) => state.profile.user?._id);

  const userId = isImpersonating ? impersonatedUser?._id : profileId;

  const [order, setOrder] = useState('createdAt');

  const getProspects = useStoreActions((actions) => actions.prospect.getProspects);
  const getResponseTimes = useStoreActions((actions) => actions.prospect.getResponseTimes);

  const refreshProspects = async () => {
    await getProspects({ order, userId: userId! });
    getResponseTimes({ userId: userId! });
  };

  const renderSortIcon = (sortValue: string, sortText: string, order = 'createdAt') => {
    const isDescending = order.includes('-');
    const orderValue = order.replace('-', '');

    let icon = faSort;
    let sortOrder = sortValue;

    if (orderValue === sortValue) {
      icon = isDescending ? faSortDown : faSortUp;
      sortOrder = isDescending ? sortValue : `-${sortValue}`;
    }

    return (
      <>
        <Col className="pl-3">
          <Row className="py-3 d-flex align-items-center">
            <Col className="d-flex align-items-center">
              <button
                type="button"
                className="listItemName text-secondary"
                data-testcafe={`sort-${sortValue}`}
                onClick={() => setOrder(sortOrder)}
              >
                {`${sortText} `}
                <FontAwesomeIcon icon={icon as any} />
              </button>
            </Col>
          </Row>
        </Col>
      </>
    );
  };

  useEffect(() => {
    if (userId) refreshProspects();

    // set auto refresh interval
  }, [order, userId]);

  // unmount auto refresh interval

  useEffect(() => {
    if (prospectListState.pending) {
      (ref.current as any).continuousStart(
        TOP_LOAD_START,
        TOP_LOAD_RATE,
      );
    }

    if (prospectListState.success || prospectListState.failure) (ref.current as any).complete();
  }, [prospectListState]);

  let background = PROSPECT_BACKGROUNDS.EMPTY;
  let link = (
    <p>
      {'No records to display. '}
      <NavLink to="/campaigns/new">Start a campaign</NavLink>
      {' to see your leads appear here!'}
    </p>
  );

  if (profile?.metadata?.allCampaignsExport) {
    background = PROSPECT_BACKGROUNDS.EXPORT;
    link = (
      <p>
        All of your prospects are set to export. Check your CRM to see what&apos;s new.
      </p>
    );
  } else if (profile?.metadata?.hadLead?.value) {
    background = PROSPECT_BACKGROUNDS.CONVERT_LEADS;
    link = (
      <p>
        No records to display. Check back to see leads converting to prospects.
      </p>
    );
  }

  return (
    <Container className="d-flex justify-content-center">
      <TopLoader reference={ref} />
      <Col style={{ width: '90vw' }}>
        <ListHeader
          display={prospectListState.success && !prospects.length}
          background={{
            image: background,
            description: 'Empty Prospect List',
          }}
          link={link}
        />
        {!profile?.metadata?.allCampaignsExport && (
          <Row className="flex rounded">
            {renderSortIcon('firstName', 'Fullname', order)}
            <Col className="pl-3">
              <Row className="py-3 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <p className="listItemName text-secondary">Campaign</p>
                </div>
              </Row>
            </Col>
            <Col className="pl-3">
              <Row className="py-3 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <p className="listItemName text-secondary">Last Response</p>
                </div>
              </Row>
            </Col>
            <Col className="pl-3">
              <Row className="py-3 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <p className="listItemName text-secondary">
                    Average Response Time
                  </p>
                </div>
              </Row>
            </Col>
          </Row>
        )}
        {prospectListState.success
          && responseTimeListState.success
          && prospects.map((prospect) => (
            <ProspectListItem
              key={genKey()}
              prospect={prospect}
              responseTimes={responseTimes}
            />
          ))}
      </Col>
    </Container>
  );
};

export default Prospects;

export interface APICallState {
  failure: boolean;
  success: boolean;
  pending: boolean;
}

export interface APIListQuery {
  ordering: string,
  filter: Object,
  limit: number,
  skip: number,
}

export enum ToastType {
  info,
  success,
  error,
  warning,
  default,
}

export interface ToastNotification {
  type: ToastType,
  message: string,
  stay?: boolean,
}

export interface MongoRecord {
  _id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface NotifyMetadata {
  value: boolean;
  notify: boolean;
}

export interface UserMetadata extends MongoRecord {
  lastLogin?: Date;
  setupLeads?: boolean;
  hadProspect?: NotifyMetadata;
  hadLead?: NotifyMetadata;
  // Aggregate data
  allCampaignsExport?: boolean;
}

export interface Team extends MongoRecord {
  tenantId: string;
  name: string;
}

export interface TeamUser extends MongoRecord {
  userId: string;
  teamId: string;
  isLead: boolean;
}

export enum UserRole {
  Admin = 'Admin',
  Owner = 'Owner',
  TeamLeader = 'Team Leader',
  TeamMember = 'Team Member',
}

export interface User extends MongoRecord {
  password?: string;
  firstName: string;
  lastName: string;
  email: string;
  tenantId: string;
  role: UserRole;
  metadata?: UserMetadata;
  // Other Create Date
  teamId?: string;
  // Aggregate data
  teams?: TeamUser[];
  avgResponse?: string;
}

export interface Tenant extends MongoRecord {
  name: string;
  domain: string;
  logoUrl?: string;
  faviconUrl?: string;
  backgroundUrl?: string;
  backgroundColor?: string;
}

export enum TouchpointType {
  Email = 'email',
  FancyEmail = 'fancy_email',
  SMS = 'sms',
  RVM = 'rvm',
}

export interface Touchpoint extends MongoRecord {
  name: string;
  type: TouchpointType;
  subject?: string;
  message?: string;
  rvmUrl?: string;
  intervalTime: {
    timeType: string;
    interval: number;
  };
  attachments?: {
    s3Path: string;
    match: string;
  }[];
  newFile?: any;

  // Used when redering the touchpoints in the campaign forms
  key?: string;
}

export interface Campaign extends MongoRecord {
  userId?: string;
  name: string;
  running?: boolean;
  exportProspects: boolean;
  touchpoints: Touchpoint[];
}

export enum ProspectStatus {
  Lead = 'Lead',
  Prospect = 'Prospect',
}

export interface Lead extends MongoRecord {
  campaignId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  status: ProspectStatus;
  isTest: boolean;

  // Aggregate data
  campaign?: Campaign;
}

export interface ProspectTouchpoint {
  touchpointId: string;
  timeCompleted?: Date;
  timeScheduled?: Date;
}

export interface Prospect extends Lead {
  companyName: string;
  nextTouchpoint: ProspectTouchpoint;
  touchpointsCompleted: ProspectTouchpoint[];
}

export interface ResponseTime extends MongoRecord {
  prospectId: string;
  messageId: string;
  userId: string;
  responseTime: {
    startTime: Date;
    endTime: Date;
  };
}

export enum MessageType {
  SMS = 'SMS',
  Email = 'Email',
  RVM = 'rvm',
}

export enum MessageStatus {
  Received = 'Received',
  Delivered = 'Delivered',
  Outgoing = 'Outgoing',
}

export interface Message extends MongoRecord {
  rvmUrl?: string;
  body?: string;
  to: string;
  externalId: string;
  prospectId: string;
  timestamp: Date;
  type?: MessageType;
  status?: MessageStatus;
  // Old field names
  messageType?: MessageType;
  messageStatus?: MessageStatus;
}

export interface Subscription extends MongoRecord {
  userId: string;
  tenantId: string;
  customerId: string;
  subscriptionId?: string;
  productId?: string;
  active: Boolean;
  isTrial: Boolean;
  customerInteractions: number;
  availableFeatures: string[];
  dateSubscribed?: Date;
  renewalDate?: Date;

  // Aggregate Data
  name?: string;
  messagesUsed?: number;
}

enum ProductInterval {
  Month = 'month',
  Year = 'year',
}

export interface Product extends MongoRecord {
  productId: string;
  priceId: string;
  name: string;
  price: number;
  defaultFeatures: string[];
  defaultCustomerInteractions: number;
  isCustomProduct: Boolean;
  archived: Boolean;
  interval: ProductInterval;
}

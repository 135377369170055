/* eslint-disable */
import React from 'react';
import {
  Navbar,
} from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';

import TabletNav from './TabletNav';
import DesktopNav from './DesktopNav';

import { useStoreState, useStoreActions } from '../../store';

import '../../../styles/header.scss';

const Header = () => {
  const { logout } = useAuth0();

  const profile = useStoreState((state) => state.profile.user);
  const profileTenant = useStoreState((state) => state.profile.tenant);

  const {
    user: impersonatedUser,
    isImpersonating,
  } = useStoreState((state) => state.impersonation);

  const friendlyName = isImpersonating
    ? `${impersonatedUser?.firstName} ${impersonatedUser?.lastName}`
    : profile ? `${profile?.firstName} ${profile?.lastName}` : '';

  const getResponseTimes = useStoreActions((actions) => actions.prospect.getResponseTimes);
  const endImpersonation = useStoreActions((actions) => actions.impersonation.resetImpersonation);

  const handleSignOut = () => {
    endImpersonation();
    logout({
      returnTo: window.location.origin
    })
  }

  const stopImpersonation = async () => {
    await endImpersonation();
    getResponseTimes({ userId: profile?._id! });
  }

  return (
    <>
      <Navbar expand="md" className="py-0">
        <DesktopNav
          tenant={profileTenant}
          friendlyName={friendlyName}
          isImpersonating={isImpersonating}
          signout={handleSignOut}
          endImpersonation={stopImpersonation}
        />
        <TabletNav
          tenant={profileTenant}
          friendlyName={friendlyName}
          isImpersonating={isImpersonating}
          signout={handleSignOut}
          endImpersonation={stopImpersonation}
        />
      </Navbar>
    </>
  );
};

export default Header;

import React, { useEffect, useState } from 'react';
import {
  Row,
  Button,
  Col,
} from 'reactstrap';

import noop from 'lodash.noop';
import cloneDeep from 'lodash.clonedeep';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NameInput from './NameInput';
import ExportInput from './ExportInput';
import TouchPointInput, { TouchpointSchema } from './Touchpoint';

import genKey from '../../../utils/genKey';

import { Campaign, Touchpoint, TouchpointType } from '../../../types';
import { TOUCHPOINT_DEFAULT_MESSAGE } from '../../../constants';

import { useStoreState } from '../../store';

import '../../../styles/contentbuilder.scss';

// Public Variable From Content Builder
// declare const ContentBuilder: any;

const CampaignSchema = () => Yup.object().shape({
  name: Yup.string().required('Required'),
  touchpoints: Yup.array().of(TouchpointSchema()),
});

// TODO: Upgrade and re implement the content builder
const CampaignForm = ({
  disabled = false,
  campaign,
  onSubmit,
  onCancel,
  availableFeatures,
}: {
  disabled?: boolean,
  campaign?: Campaign
  onSubmit: (data: Campaign) => void,
  onCancel: Function,
  availableFeatures: string[],
}) => {
  const editCampaignState = useStoreState((state) => state.campaign.editState);
  const createCampaignState = useStoreState((state) => state.campaign.createState);

  const saveState = campaign ? editCampaignState : createCampaignState;

  const [name, setName] = useState(campaign?.name ?? 'New Campaign');
  const [exportProspects, setExportProspects] = useState(campaign?.exportProspects ?? false);
  const [touchpoints, setTouchpoints] = useState<Touchpoint[]>(campaign?.touchpoints
    ? cloneDeep(campaign.touchpoints)
    : [
      {
        key: genKey(),
        name: 'Touchpoint #1',
        type: TouchpointType.SMS,
        subject: '',
        message: TOUCHPOINT_DEFAULT_MESSAGE.sms,
        intervalTime: {
          interval: 0,
          timeType: 'hours',
        },
      },
    ]);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: CampaignSchema,
    initialValues: {
      name,
      touchpoints,
    },
    onSubmit: noop,
  });

  const [isValid, setIsValid] = useState(false);

  const onNewTouchpoint = () => {
    const newTouchpoints = cloneDeep(touchpoints);

    const basicTouchpointData = {
      key: genKey(),
      name: `Touchpoint #${touchpoints.length + 1}`,
      type: TouchpointType.SMS,
      subject: '',
      message: TOUCHPOINT_DEFAULT_MESSAGE.sms,
      intervalTime: {
        interval: 1,
        timeType: 'hours',
      },
    };

    newTouchpoints.push(basicTouchpointData);

    setTouchpoints(newTouchpoints);
  };

  const onDeleteTouchpoint = (index: number) => {
    const newTouchpoints = cloneDeep(touchpoints);

    setTouchpoints(newTouchpoints.filter((t, tI) => tI !== index));
  };

  const onUpdateTouchpoint = (index: number, touchpoint: Touchpoint) => {
    const newTouchpoints = cloneDeep(touchpoints);

    newTouchpoints[index] = touchpoint;

    // eslint-disable-next-line no-confusing-arrow
    setTouchpoints(newTouchpoints.map((t, i) => i !== index ? t : ({ ...touchpoint })));

    // touchpoint type changed to email
    // if (target === 'type' && value === 'email') {
    //   setTimeout(
    //     () => setContentBuilderTarget(index),
    //     500,
    //   );
    // }
  };

  const onSave = async () => {
    // const targetTouchpoint = contentBuilderData.touchpointIndex
    //   ? touchpoints[contentBuilderData.touchpointIndex]
    //   : null;

    // if (targetTouchpoint?.type === 'email') {
    //   targetTouchpoint.message = contentBuilderData.contentBuilder.html();
    // }

    // if (contentBuilderData.contentBuilder) {
    //   contentBuilderData.contentBuilder.destroy();
    // }

    onSubmit({
      running: true,
      name,
      exportProspects,
      touchpoints,
    });
  };

  useEffect(() => {
    try {
      (async () => {
        setIsValid((await CampaignSchema().isValid({ name, touchpoints })));

        return true;
      })();
    } catch (ex) {
      console.log(ex);
    }
  }, [name, touchpoints]);

  return (
    <>
      <Row className="align-items-start justify-content-between pt-2">
        <div>
          <div>
            <NameInput disabled={disabled} formik={formik} setValue={setName} />
          </div>
          <div className="d-flex" style={{ marginLeft: '20px' }}>
            <ExportInput
              disabled={disabled}
              value={exportProspects}
              setValue={setExportProspects}
            />
          </div>
        </div>
        {!disabled && (
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex">
              <Button
                color="primary"
                className="m-1"
                type="submit"
                disabled={!isValid || saveState.pending}
                onClick={onSave}
              >
                Save Campaign
              </Button>
              <Button
                id="buttonCampaignCancel"
                color="secondary"
                className="m-1"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </Row>
      <Row>
        <Col>
          {touchpoints.map((touchpoint, index) => (
            <TouchPointInput
              key={touchpoint?._id ?? touchpoint?.key}
              disabled={disabled}
              index={index}
              touchpoint={touchpoint}
              availableFeatures={availableFeatures}
              previousTouchpointName={index ? touchpoints[index - 1].name : null}
              deleteTouchpoint={() => onDeleteTouchpoint(index)}
              updateTouchpoint={(data) => onUpdateTouchpoint(index, data)}
              // usingContentBuilder={
              //   contentBuilderData.touchpointIndex === index
              // }
              // setContentBuilderTarget={setContentBuilderTouchpointTarget}
            />
          ))}
          {touchpoints.length < 5 && !disabled ? (
            <Row className="mb-3">
              <Button
                className="col-12 mx-0 mt-4 pl-0 d-flex align-items-center justify-content-start"
                color="secondary"
                outline
                onClick={onNewTouchpoint}
              >
                <FontAwesomeIcon icon={faPlusCircle as any} className="mx-4" />
                <span>Add Touchpoint</span>
              </Button>
            </Row>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </>
  );
};

// const [contentBuilderData, setContentBuilderData] = useState<{
//   touchpointIndex: number | null,
//   contentBuilder: any }
// >({
//   touchpointIndex: null,
//   contentBuilder: null,
// });

// const unlisten = navigate.listen(() => {
//   if (contentBuilderData.contentBuilder)
//     contentBuilderData.contentBuilder.destroy();
//   unlisten();
// });

// const setContentBuilderTouchpointTarget = (index: number) => {
//   if (contentBuilderData.contentBuilder) {
//     const newTouchpoints = cloneDeep(touchpoints);
//     const touchpoint = newTouchpoints[contentBuilderData.touchpointIndex!];
//     const target = newTouchpoints[index];
//     touchpoint.message = contentBuilderData.contentBuilder.html();
//     target.type = 'email';
//     target.message = TOUCHPOINT_DEFAULT_MESSAGE.email;
//     contentBuilderData.contentBuilder.destroy();
//     setTouchpoints(newTouchpoints);
//   }
//   // eslint-disable-next-line no-undef
//   const newContentBuilder = new ContentBuilder({
//     container: `.container-touchpoint-${index}`,
//     builderMode: '',
//     toolbar: 'top',
//     columnTool: true,
//     rowTool: 'right',
//     elementTool: true,
//     snippetAddTool: true,
//     outlineMode: '',
//     rowcolOutline: true,
//     outlineStyle: '',
//     elementHighlight: true,
//     snippetOpen: false,
//     toolStyle: '',
//     snippetsSidebarDisplay: 'auto',
//     clearPreferences: true, // reset settings on load
//   });
//   setContentBuilderData({
//     touchpointIndex: index,
//     contentBuilder: newContentBuilder,
//   });
// };

export default CampaignForm;

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment,
  faEnvelope,
  faVoicemail,
  faExpand,
} from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import {
  EXPAND_AT,
  ELLIPSIZE_AT,
  DEFAULT_CALL_RECEIVED_MSG,
} from '../../constants';

import {
  Message,
  MessageStatus,
  MessageType,
  ProspectStatus,
  UserRole,
} from '../../types';

import '../../styles/prospect.scss';
import formatDate from '../../utils/moments';

const MessageItem = ({
  message,
}: {
  message: Message
}) => {
  const {
    rvmUrl,
    body,
    timestamp,
    type,
    status,
    // Old field names
    messageType,
    messageStatus,
  } = message;

  const [isOpen, setIsOpen] = useState(false);

  const { Prospect } = ProspectStatus;
  const TeamMember = UserRole.TeamMember.replace(' ', '');

  const perspective = (status ?? messageStatus!) === MessageStatus.Received ? Prospect : TeamMember;
  const displayDate = formatDate(timestamp);

  const ellipsizedBody = body && body.length > EXPAND_AT
    ? `${body.substring(0, ELLIPSIZE_AT)} …`
    : body;

  const displayedBody = type === MessageType.RVM
    && body !== DEFAULT_CALL_RECEIVED_MSG ? (
      <a href={rvmUrl} target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
        Voicemail
      </a>
    ) : (ellipsizedBody);

  const internalToggle = () => setIsOpen(!isOpen);

  const getIcon = (type: MessageType) => {
    switch (type) {
      case MessageType.SMS:
        return faComment;
      case MessageType.RVM:
        return faVoicemail;
      case MessageType.Email:
      default:
        return faEnvelope;
    }
  };

  return (
    <>
      <div
        className={`col-sm-12 col-md-7 col-lg-6  msgContainer${perspective}`}
      >
        <div className={`date${perspective}`}>{displayDate}</div>
        <div className={`message ${perspective}`}>
          {perspective === TeamMember && (
            <div className="messageTypeContainer">
              <FontAwesomeIcon icon={getIcon(type ?? messageType!) as any} />
            </div>
          )}
          <div className="content">
            {perspective === Prospect && body && body.length > EXPAND_AT && (
              <div
                style={{ marginRight: '20px' }}
              >
                <FontAwesomeIcon icon={faExpand as any} onClick={internalToggle} />
              </div>
            )}
            {body && (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxHeight: '150px',
                }}
              >
                {displayedBody}
              </div>
            )}
            {perspective !== Prospect && body && body.length > EXPAND_AT && (
              <div
                style={{ marginLeft: '20px' }}
              >
                <FontAwesomeIcon icon={faExpand as any} onClick={internalToggle} />
              </div>
            )}
          </div>
          {perspective === Prospect && (
            <div className="messageTypeContainer">
              {type === MessageType.SMS ? (
                <FontAwesomeIcon icon={faComment as any} />
              ) : (
                <FontAwesomeIcon icon={faEnvelope as any} />
              )}
            </div>
          )}
        </div>
      </div>
      <Modal
        centered
        isOpen={isOpen}
        toggle={internalToggle}
        style={{ minWidth: '50vw' }}
      >
        <ModalHeader toggle={internalToggle} />
        <ModalBody style={{ maxHeight: '75vh', overflow: 'scroll' }}>
          {body}
        </ModalBody>
      </Modal>
    </>
  );
};

export default MessageItem;

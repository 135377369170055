import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faComment,
  faCog,
  faTrashAlt,
  faPause,
  faPencilAlt,
  faFolderPlus,
  faVoicemail,
} from '@fortawesome/free-solid-svg-icons';

import AddTestLead from './Modals/AddTestLead';
import ConfirmationModal from './Modals/ConfirmationModal';

import { Campaign, TouchpointType } from '../../types';

import { useStoreActions } from '../store';

import '../../styles/campaign.scss';

const CampaignListItem = ({
  campaign,
  inImpersonationMode,
  refreshCampaigns,
}: { campaign: Campaign, inImpersonationMode: boolean, refreshCampaigns: Function }) => {
  const {
    _id,
    name,
    touchpoints,
    running,
  } = campaign;

  const [isActionsOpen, setIsActionsOpen] = useState(false);
  const [isAddLeadOpen, setIsAddLeadOpen] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

  const navigate = useNavigate();

  const editCampaign = useStoreActions((actions) => actions.campaign.editCampaign);
  const removeCampaign = useStoreActions((actions) => actions.campaign.removeCampaign);

  const toggleRunning = async () => {
    await editCampaign({ id: _id!, data: { running: !running } as any });
    refreshCampaigns();
  };

  const deleteCampaign = async () => {
    await removeCampaign(_id!);
    refreshCampaigns();
  };

  const navigateToCampaign = () => navigate(inImpersonationMode
    ? `/campaigns/${_id}`
    : `/campaigns/${_id}/edit`);

  const getIcon = (type: TouchpointType) => {
    switch (type) {
      case TouchpointType.SMS:
        return faComment;
      case TouchpointType.RVM:
        return faVoicemail;
      case TouchpointType.Email:
      default:
        return faEnvelope;
    }
  };

  const displayTime = (intervalTime: { timeType: string, interval: number }) => {
    const {
      timeType,
      interval,
    } = intervalTime;

    let str = '';

    switch (timeType) {
      case 'minutes':
        str = 'min';
        break;
      case 'hours':
        str = 'hr';
        break;
      case 'days':
        str = 'day';
        break;
      default:
        str = 'hr';
        break;
    }

    return `${interval} ${interval === 1 ? str : `${str}s`}`;
  };

  return (
    <Row className="flex border border-secondary rounded my-4">
      <Col className="pl-3">
        <Row className="py-3 d-flex align-items-center">
          <Col className="d-flex align-items-center">
            <button
              type="button"
              className="listItemName link text-primary"
              onClick={() => navigateToCampaign()}
            >
              {name}
            </button>
          </Col>
          <Col xs="12" md="9" className="d-flex flex-wrap align-items-center">
            {touchpoints.map((touchpoint, i) => (
              <>
                {i !== 0 ? (
                  <svg
                    width="110"
                    height="55"
                    viewBox="0 0 51 18"
                    fill="#6c757d"
                    style={{ strokeWidth: '0px', backgroundColor: 'white' }}
                  >
                    <path d="M39 2h-33l6 7-6 7h33l6-7z" />
                    <text
                      x="50%"
                      y="50%"
                      dominantBaseline="middle"
                      textAnchor="middle"
                      fill="white"
                      style={{ fontSize: '6px', zIndex: '1' }}
                    >
                      {displayTime(touchpoint.intervalTime)}
                    </text>
                  </svg>
                ) : (
                  false
                )}
                <div
                  className="bubble d-flex justify-content-center align-items-center bg-primary"
                  style={{
                    width: '45px',
                    height: '45px',
                    borderRadius: '50%',
                  }}
                >
                  <FontAwesomeIcon className="campaign-sms" icon={getIcon(touchpoint.type) as any} />
                </div>
              </>
            ))}
          </Col>
        </Row>
      </Col>
      <Col
        className="campaign-settings
          col-auto d-flex border-left border-secondary
          justify-content-center align-items-center"
      >
        {/* TODO: Style the dropdown modal */}
        <Dropdown
          isOpen={isActionsOpen}
          disabled={inImpersonationMode}
          toggle={() => setIsActionsOpen(!isActionsOpen)}
        >
          <DropdownToggle>
            <FontAwesomeIcon
              icon={faCog as any}
              className="cog text-secondary"
            />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setIsAddLeadOpen(true)}>
              <FontAwesomeIcon className="mr-2" icon={faFolderPlus as any} />
              Test Campaign
            </DropdownItem>
            <DropdownItem onClick={() => toggleRunning()}>
              <FontAwesomeIcon className="mr-2" icon={faPause as any} />
              {running ? 'Pause Campaign' : 'Resume Campaign'}
            </DropdownItem>
            <DropdownItem onClick={() => navigateToCampaign()}>
              <FontAwesomeIcon className="mr-2" icon={faPencilAlt as any} />
              Edit Campaign
            </DropdownItem>
            <DropdownItem onClick={() => setIsConfirmDeleteOpen(true)}>
              <FontAwesomeIcon className="mr-2" icon={faTrashAlt as any} />
              Delete Campaign
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Col>
      <ConfirmationModal
        isOpen={isConfirmDeleteOpen}
        title="Are you sure you want to delete this campaign?"
        onCancel={() => setIsConfirmDeleteOpen(false)}
        onConfirm={() => deleteCampaign()}
      />
      <AddTestLead
        campaignId={_id as string}
        isOpen={isAddLeadOpen}
        toggle={() => setIsAddLeadOpen(false)}
      />
    </Row>
  );
};

export default CampaignListItem;

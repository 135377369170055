import React, { useState } from 'react';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';

import noop from 'lodash.noop';

import { Lead } from '../../../types';
import { useStoreActions, useStoreState } from '../../store';

const LeadSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .required('Required')
    .email('Invalid email'),
  phoneNumber: Yup.string()
    .required('Required')
    .phone('US', 'Invalid Phone Number'),
});

const AddTestLead = ({
  isOpen,
  toggle,
  campaignId,
}: {
  isOpen: boolean,
  toggle: Function,
  campaignId: string,
}) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = useFormik({
    validationSchema: LeadSchema,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
    onSubmit: noop,
  });

  const [lead, setLead] = useState({
    campaignId,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  } as Lead);

  const isLeadValid = LeadSchema.isValidSync(lead);

  const createState = useStoreState((state) => state.lead.createState);

  const createLead = useStoreActions((actions) => actions.lead.createLead);

  const setValue = (target: string, value?: string) => setLead({
    ...lead,
    [target]: value,
  });

  const save = () => {
    createLead(lead);
    toggle();
  };

  return (
    <Modal
      centered
      backdrop="static"
      isOpen={isOpen}
      style={{ minWidth: '30vw' }}
    >
      <ModalHeader toggle={() => toggle()} />
      <ModalBody style={{ minHeight: '35vh' }}>
        <Form>
          <FormGroup>
            <InputGroup>
              <Input
                type="text"
                name="firstName"
                placeholder="First Name"
                onChange={(e) => {
                  handleChange(e);
                  setValue(e.target.name, e.target.value);
                }}
                onBlur={handleBlur}
                value={values.firstName}
                invalid={touched.firstName && !!errors.firstName}
              />
              <FormFeedback>{errors.firstName}</FormFeedback>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <Input
                type="text"
                name="lastName"
                placeholder="Last Name"
                onChange={(e) => {
                  handleChange(e);
                  setValue(e.target.name, e.target.value);
                }}
                onBlur={handleBlur}
                value={values.lastName}
                invalid={touched.lastName && !!errors.lastName}
              />
              <FormFeedback>{errors.lastName}</FormFeedback>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <Input
                type="text"
                name="email"
                placeholder="Email"
                onChange={(e) => {
                  handleChange(e);
                  setValue(e.target.name, e.target.value);
                }}
                onBlur={handleBlur}
                value={values.email}
                invalid={touched.email && !!errors.email}
              />
              <FormFeedback>{errors.email}</FormFeedback>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <Input
                type="text"
                name="phoneNumber"
                placeholder="Phone Number"
                onChange={(e) => {
                  handleChange(e);
                  setValue(e.target.name, e.target.value);
                }}
                onBlur={handleBlur}
                value={values.phoneNumber}
                invalid={touched.phoneNumber && !!errors.phoneNumber}
              />
              <FormFeedback>{errors.phoneNumber}</FormFeedback>
            </InputGroup>
          </FormGroup>
          <FormGroup className="d-flex align-items-center justify-content-center">
            <Button
              color="primary"
              onClick={save}
              disabled={!isLeadValid || createState.pending}
              style={{ width: '100%' }}
            >
              <FontAwesomeIcon className="mr-2" icon={faFolderPlus as any} />
              {' Add Test Lead'}
            </Button>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AddTestLead;

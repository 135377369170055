import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Auth0Lock from 'auth0-lock';
import {
  Alert,
  Col,
  Row,
  Form,
  FormGroup,
} from 'reactstrap';

import useQuery from '../hooks/useQuery';
import { useStoreState } from '../store';

import { DEFAULT_LOGO_IMAGE } from '../../constants';

// Change to entry.scss
import '../../styles/entry.scss';

const Entry = () => {
  const auth0Config = {
    allowSignUp: false,
    container: 'login',
    theme: {
      primaryColor: '#1E4962',
      foregroundColor: '#FFFFFF',
    },
  };

  const tenant = useStoreState((state) => state.tenant.tenant);

  const logo = tenant?.logoUrl || DEFAULT_LOGO_IMAGE;
  const pageStyle = tenant?.backgroundColor
    ? { backgroundColor: tenant?.backgroundColor }
    : {};

  const background = tenant?.backgroundUrl
    ? { backgroundImage: `url(${tenant?.backgroundUrl})` }
    : pageStyle;

  const { loginWithRedirect } = useAuth0();

  const lock = new Auth0Lock(
    process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
    process.env.REACT_APP_AUTH0_DOMAIN ?? '',
    auth0Config,
  );

  lock.on('authenticated', () => loginWithRedirect());

  const query = useQuery();
  const isVerified = query.get('verified') === '1';

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      lock.show();
    }
  };

  useEffect(() => {
    lock.show();
  }, [lock]);

  return (
    <Col id="loginPage" style={pageStyle}>
      <div id="loginContainer" style={background}>
        <img id="loginLogo" alt="Main Logo" src={logo} />
        <Form id="loginForm" onKeyDown={(e) => handleKeyDown(e)}>
          <FormGroup>
            <Row>
              <Col className="loginButtonContainer">
                {isVerified && (
                  <Alert>
                    <h4>Account verified!</h4>
                    <p>Log in to get started</p>
                  </Alert>
                )}
                <div id="login" />
              </Col>
            </Row>
            <Row>
              <Col className="loginButtonContainer">
                <NavLink id="signUp" to="/signup">Sign Up</NavLink>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </div>
    </Col>
  );
};

export default Entry;

import React from 'react';

import moment from 'moment';
import 'moment-duration-format';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { Prospect, ResponseTime } from '../../types';
import { MOMENT_FORMATS } from '../../constants';

// TODO: Compile the avgResponse time and the last response time in the api not here
const ProspectListItem = ({
  prospect,
  responseTimes,
}: { prospect: Prospect, responseTimes: ResponseTime[] }) => {
  const formatAvgResponseTime = (responseTimes: ResponseTime[]) => {
    if (!responseTimes
      || responseTimes.length === 0
      || (responseTimes.length === 1 && !responseTimes[0].responseTime.endTime)
    ) return 'Not Responded Yet';

    const totalResponseTime = responseTimes.reduce(
      (total, { responseTime }) => {
        const { startTime, endTime } = responseTime;
        const startMoment = moment(startTime);
        const endMoment = endTime ? moment(endTime) : moment();
        return total + endMoment.diff(startMoment);
      },
      0,
    );

    const averageResponse = totalResponseTime / responseTimes.length;

    return (moment
      .duration(averageResponse, 'milliseconds') as any)
      .format(MOMENT_FORMATS.TIME_ELAPSED, {
        useSignificantDigits: true,
        precision: 2,
        trunc: true,
      });
  };

  const prospectsResponseTimes = responseTimes.filter(
    (responseTime) => responseTime.prospectId === prospect._id,
  );

  const {
    responseTime: { startTime, endTime },
  } = prospectsResponseTimes[0] || {
    responseTime: { startTime: '', endTime: '' },
  };

  const lastResponse = (startTime
    && moment(startTime).format(MOMENT_FORMATS.MONTH_DAY_TIME_PERIOD))
    || 'Unavailable';

  const responseTime = formatAvgResponseTime(prospectsResponseTimes);

  return (
    <Row
      className={`flex border border-${
        !endTime ? 'danger' : 'secondary'
      } rounded mb-4`}
    >
      <Col className="pl-3">
        <Row className="py-3 d-flex align-items-center">
          <Col className="d-flex align-items-center">
            <p className="listItemName text-primary">
              {`${prospect.firstName} ${prospect.lastName}`}
            </p>
          </Col>
        </Row>
      </Col>
      <Col className="pl-3">
        <Row className="py-3 d-flex align-items-center">
          <Col className="d-flex align-items-center">
            <p className="listItemName text-primary">{prospect.campaign?.name ?? 'Unknown'}</p>
          </Col>
        </Row>
      </Col>
      <Col className="pl-3">
        <Row className="py-3 d-flex align-items-center">
          <Col className="d-flex align-items-center">
            <p className="listItemName text-primary">{lastResponse}</p>
          </Col>
        </Row>
      </Col>
      <Col className="pl-3">
        <Row className="py-3 d-flex align-items-center">
          <Col className="d-flex align-items-center justify-content-center">
            <p className="listItemName text-primary">{responseTime}</p>
          </Col>
        </Row>
      </Col>
      <Col className="col-auto d-flex justify-content-center align-items-center">
        <Link
          to={`/prospects/${prospect._id}`}
        >
          <FontAwesomeIcon icon={faChevronRight as any} />
        </Link>
      </Col>
    </Row>
  );
};

export default ProspectListItem;
